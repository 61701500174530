import React, { useContext, useEffect } from "react"
import Layout from '../components/Layout';
import Button from "../components/Button";
import { GlobalContext } from "../providers/GlobalProvider";

import "../css/404.css";


export default function PageNotFound() {
    const { setPageScrolled,setPageScrollUp } = useContext(GlobalContext);

    useEffect(() => {
      setPageScrolled(false);
      setPageScrollUp(false);
    }, []);

    return (
        <Layout noPrefooter>
            <div className="container404" id="404_layout">
                <span className="headerStyles">404</span>
                <span className="subHeadStyles">
                    umm…. we’re not sure how you got here
                </span>
                <Button text="Scoot Back Home" bgColor="#fff" color="#1a1f2c" ajax="true" url="/" emoji="🛴" emoji2="💨" emojiId1="dash" emojiId2="disappear" />
            </div>
        </Layout>
    )
}


